import PageContainer from "../../../components/container/PageContainer";
import { TbReportAnalytics } from "react-icons/tb";
import { LuListTree } from "react-icons/lu"
import {Link} from "react-router-dom";
import Storage from "../../../helpers/Storage";
export default function MyReports() {
    const userToken: any = Storage.getApiToken();
    return (
        <PageContainer>
            <div className={'w-full flex justify-center gap-3'}>
                <Link to={process.env.REACT_APP_API+'/relatorios/'+userToken} target={'_blank'}
                   className={'flex flex-col w-[250px] h-[250px] justify-center border border-gray-400 rounded-md items-center'}>
                    <TbReportAnalytics size={60}/>
                    <div className={'text-[20px] font-semibold'}>
                        Meus relatórios
                    </div>
                </Link>

                <Link to={'/testes'} className={'flex flex-col w-[250px] h-[250px] justify-center border border-gray-400 rounded-md items-center'}>
                    <LuListTree size={60}/>
                    <div className={'text-[20px] font-semibold'}>
                        Meus testes
                    </div>
                </Link>
            </div>
        </PageContainer>
    )
}