import { useCallback, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
function Button2({ content, onClick, active, disabled }: any) {

  return (
      <button
        className={`flex flex-col rounded-full  hover:bg-[#42B14B] hover:text-white  cursor-pointer items-center border justify-center w-9 h-9  text-sm font-normal transition-colors
            ${active ? "border-2 border-border bg-white text-black" : "bg-[#2C2C36] text-white"}
            ${!disabled ? "bg-[#2C2C36] hover:bg-[#e3e3e5]" : " bg-[#3c3c49]"}
        `}
        onClick={onClick}
        disabled={disabled}
      >
        {content}
      </button>
  );
}

function Pagination({
  gotoPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
  lastPage
}: any) {
  const renderPageLinks = useCallback(() => {
    if (pageCount === 0) return null;
    const visiblePageButtonCount = lastPage < 4 ? lastPage : 4;
    let numberOfButtons =
        pageCount < visiblePageButtonCount ? pageCount : visiblePageButtonCount;
    const pageIndices = [pageIndex];

    numberOfButtons--;
    [...Array(numberOfButtons)].forEach((_item, itemIndex) => {
      const pageNumberBefore = pageIndices[0] - 1;
      const pageNumberAfter = pageIndices[pageIndices.length - 1] + 1;

      if (
          pageNumberBefore >= 0 &&
          (itemIndex < numberOfButtons / 2 || pageNumberAfter > pageCount - 1)
      ) {
        pageIndices.unshift(pageNumberBefore);
      } else {
        pageIndices.push(pageNumberAfter);
      }
    });

    return pageIndices.map((pageIndexToMap) => (
        <li key={pageIndexToMap}>
          <Button2
              content={pageIndexToMap + 1}
              onClick={() => gotoPage(pageIndexToMap)}
              active={pageIndex === pageIndexToMap}
          />
        </li>
    ));
  }, [pageCount, pageIndex]);
  return (
      <ul className="flex gap-2">
        <li>
          <Button2
              content={
                <div className="flex">
                    <MdKeyboardArrowLeft />
                </div>
              }
              onClick={() => gotoPage(pageIndex -1)}
              disabled={!canPreviousPage}
          />
        </li>
        {renderPageLinks()}
        <li>
          <Button2
              content={
                <div className="flex">
                  <MdKeyboardArrowRight/>
                </div>
              }
              onClick={() => gotoPage(pageIndex + 1)}
              disabled={!canNextPage}
          />
        </li>
      </ul>
  );
}

function PaginationNav1Presentation({onPageChange, totalPaginas, lastPage, page}: any) {
  const [pageIndex, setPageIndex] = useState(page ? page - 1 : 0);
  const pageCount = lastPage;
  const changePage = (e: any) => {
      setPageIndex(e);
      onPageChange(e + 1);
  }

  return (
        <Pagination
            gotoPage={changePage}
            canPreviousPage={pageIndex > 0}
            canNextPage={pageIndex + 1 !== lastPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            lastPage={lastPage}
        />
  );
}

export { PaginationNav1Presentation, Pagination };
