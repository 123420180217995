import PageContainer from "../../../components/container/PageContainer";
import {useEffect, useState} from "react";
import newsApi from "../../../services/newsApi";
import CardNews from "../../../components/cards/CardNews";
import {PaginationNav1Presentation} from "../../../components/Table/Pagination";

export default function News() {
    const [list, setList] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);

    const [paginateData, setPaginatedData]: any =  useState({
        'page': 1,
        'totalPaginas': 1,
        'lastPage': 10
    });

    async function getData(pageData?: any){
        setLoading(true);
        const {data, isError} = await newsApi.getNews(pageData ? pageData : page);
        let newPaginate = {...paginateData};
        newPaginate = {
            'page': data.current_page,
            'totalPaginas': data.last_page,
            'lastPage': data.last_page
        }
        setPage(data.current_page)
        setPaginatedData(newPaginate);
        setList(data.data);
        setLoading(false)
    }



    useEffect(() => {getData()}, [])

    return(
        <PageContainer>
            <div className={'w-full  xs:gap-4 bg-white h-full'}>
            {/*<div className={'bg-white h-full'}>*/}
            {
                loading ?
                    <span className="flex items-center justify-center h-20 p-5">
                      <svg className="animate-spin -ml-1 mr-3 h-[30px] w-[30px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
                    :
                    <div className={'flex flex-col p-3 gap-3'}>
                        <div className={'text-[30px] text-semibold '}>Notícias recentes</div>
                        <div className={'flex flex-wrap gap-2 w-full '}>
                            <div className={'grid md:grid-cols-3  sm:grid-cols-2 xs:grid-cols-1 gap-1 '}>
                                {
                                    list.map((li: any) => {
                                        return <CardNews news={li} big/>;
                                    })
                                }
                            </div>
                        </div>
                        {
                            paginateData.paginateData > 1 ?
                                <div className={'w-full mt-2'}>
                                    <PaginationNav1Presentation
                                        page={1}
                                        onPageChange={(e: any) => getData(e)}
                                        totalPaginas={10}
                                        lastPage={10}
                                    />
                                </div>
                                :
                                ''
                        }


                    </div>

            }
            </div>

        </PageContainer>
    );
}