import noImage from "../../../assets/img/noImage.png";
import Storage from "../../../helpers/Storage";
import {User} from "../../../types";
import {Link, useNavigate} from "react-router-dom";
export interface ICardUser {
    userData: User,
    percent: number,
    loading?: boolean,
    disc?: any,
}
export default function CardUser({userData, percent, loading, disc}:ICardUser) {
    const userToken: any = Storage.getApiToken();
    const navigate = useNavigate();
    const bgCollor: any = {
        0: '#4285F4',
        1:'#EA4335',
        2:'#FBBC04',
        3:'#34A853',
    }
    return(
        <div className={'flex flex-col  bg-white w-full p-5 border border-white rounded-lg gap-5'}>
            <div className={'flex  gap-5'}>
                <img className={'w-[110px] h-[110px] rounded-full border'} src={userData.image ? userData.image : noImage}/>
                <div className={'flex w-[400px] flex-col gap-3 text-[#263465]'}>
                    <div className={'font-bold text-[18px]'}>
                        {userData.nome}
                    </div>
                    <div className={'text-[13px] font-semibold'}>
                        Perfil disc: {userData.perfil_disc}
                        <p/>
                        Perfil de Liderança: {userData.perfil_lideranca}
                    </div>
                    {
                        loading ?
                            <span className="flex items-center justify-center h-20 p-5">
                          <svg className="animate-spin -ml-1 mr-3 h-[15px] w-[15px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        </span>
                            :
                            <div className={'flex flex-col w-full hover:cursor-pointer '}  onClick={() => navigate('/meu-perfil')}>
                                <div className={'text-[15px] font-semibold'}>Complete seu perfil</div>
                                <div className={'flex items-center gap-1'}>
                                    <div className={`h-[8px] md:w-[150px] xs:w-[100px] bg-[#EDEFF8]  rounded-lg`}>
                                        <div className={`h-[8px] rounded-lg `} style={{width: percent+'%', backgroundColor:'#71BD00'}}/>
                                    </div>
                                    {percent+'%'}
                                </div>
                            </div>
                    }

                </div>
            </div>


            {
                loading ?
                        <span className="flex items-center justify-center h-20 p-5">
                          <svg className="animate-spin -ml-1 mr-3 h-[15px] w-[15px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                        </span>
                    :
                        <div className={'flex w-full mt-3 text-[#263465]'}>
                            <div className={'flex flex-col  w-full gap-3'}>
                                <div className={`flex h-[40px] w-full bg-[#EDEFF8]  rounded-lg`}>
                                    {
                                        !!disc.disc ?
                                            disc?.disc.testes.map((teste: any, key: number) => {

                                                return (
                                                    <div
                                                        className={`h-[50px] flex flex-col justify-center items-center text-[12px] text-[#fff] font-bold ${key === 0 ? 'rounded-l-lg' : ''}${disc?.disc.testes.length === key + 1 ? 'rounded-r-lg' : ''}`}
                                                        style={{
                                                            width: (teste.normalized_match_percent * 100) + '%',
                                                            backgroundColor: bgCollor[key]
                                                        }}>
                                                        <div>{teste.name}</div>
                                                        <div>{(teste.normalized_match_percent * 100).toString().substring(2, 0)}%</div>
                                                    </div>
                                                )
                                            })
                                            :
                                            <div className={'flex w-full items-center content-center p-3 gap-2'}>
                                                Não foram encontrado resultados de testes.
                                                <Link to={`${process.env.REACT_APP_API + '/relatorios/' + userToken}`}
                                                      target={'_blank'} className={'text-blue-500'}>cliquei aqui para
                                                    fazer o teste.</Link>
                                            </div>
                                    }

                                </div>
                                <div className={'flex flex-col gap-2 mt-3'}>
                                    <div
                                        className={'font-bold text-[18px]'}>{disc?.content ? disc?.content[0].title : ''}</div>
                                    <div className={'text-[12px]'} dangerouslySetInnerHTML={{ __html: disc?.content ? disc?.content[0].content : '' }} />
                                </div>
                            </div>
                        </div>

            }

        </div>
    )
}