import PageContainer from "../../../components/container/PageContainer";

import Storage from "../../../helpers/Storage";

import { FaCopy } from "react-icons/fa6";
import Alert from "../../../helpers/Alert";
import VirtualCardComponent from "../../../components/virtualCardComponent";
import { printWithTailwind } from "react-tailwind-printer";
export default function VirtualCard() {
    const userData: any = Storage.getUserData();

    const handlePrint = () => {
        printWithTailwind({
            title: "Cartao virtual",
            component: (
                <VirtualCardComponent userData={userData.usuario} on={true}/>
            ),
        });
    };

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text);
        Alert.success('Link copiado')
    }

    return(
        <PageContainer>
            <div className={'flex justify-between'}>
                <button
                    className={'flex justify-center items-center gap-2 bg-[#2C2C36] p-2 rounded-md text-white h-[50px]'}
                    onClick={() => handleCopy(window.location.origin + '/cartao/' + userData.usuario.nome.replace(" ", '-').toLowerCase() + '-' + userData.usuario.id)}>
                    <FaCopy size={16} title={'Copiar link'}/> Copiar link
                </button>
                <button onClick={handlePrint} className="px-4 py-2 bg-[#42B14B] text-white rounded">
                    Imprimir
                </button>
            </div>
            <VirtualCardComponent userData={userData.usuario} on={true}/>
        </PageContainer>
    )
}