import { GoHome } from "react-icons/go";
import { FaRegFileVideo } from "react-icons/fa6";
import { TbReportAnalytics } from "react-icons/tb";
import { FaPeopleRoof } from "react-icons/fa6";
import { IoTrailSignOutline } from "react-icons/io5";
import { LuListChecks, LuListTree } from "react-icons/lu";
import { FaRegAddressCard, FaRegNewspaper } from "react-icons/fa";
import { BiNetworkChart } from "react-icons/bi";
import { BsTrophy } from "react-icons/bs";
import Storage from "../helpers/Storage";
import { RiListIndefinite } from 'react-icons/ri'
import { MdOutlineVideoCameraFront, MdOutlineEvent, MdManageSearch, MdOutlineListAlt } from "react-icons/md";


export interface Menu {
    title: string;
    path?: string;
    icon?: any;
    subMenus?: Menu[];
    url?: string;
    comunidade?: boolean;
}
const userToken: any = Storage.getApiToken();

// eslint-disable-next-line import/no-anonymous-default-export
// @ts-ignore
const urlCadastro = process.env?.REACT_APP_API.replace('api', 'vagas/listar')

const userData: any = Storage.getUserData();
//console.log(userData.usuario.qtd_tests);
export default [
    {
        title: 'Home',
        path: '/home',
        icon: GoHome,
    },
    {
        title: 'Vídeo pitch',
        path: '/meu-video',
        icon: FaRegFileVideo,
    },
    {
        title: 'Meus relatórios',
        path: '/relatorio',
        icon: TbReportAnalytics,
        url: process.env.REACT_APP_API+'/relatorios/'+userToken
    },
    {
        title: 'Comunidade',
        path: '/comunidade',
        icon: FaPeopleRoof,
        url: '',
        comunidade: true
    },
    {
        title: 'Entrevista Simulada',
        path: '/entrevista-simulada',
        icon: MdOutlineVideoCameraFront,
    },
    {
        title: 'Trilhas de conteúdo',
        path: '/trilhas',
        icon: IoTrailSignOutline,
        url: 'trails',
        comunidade: true
    },
    {
        title: 'Gestão de carreira',
        path: '/gestao-carreira',
        icon: MdManageSearch,
    },
    {
        title: 'Vagas',
        path: '/vagas',
        icon: MdOutlineListAlt,
        url: urlCadastro
    },
    {
        title: 'Meus certificados',
        path: '/certificados',
        icon: LuListChecks,
    },
    /*{
        title: 'Meus testes',
        path: '/testes',
        icon: LuListTree,
    },*/
    {
        title: 'Meu cartão virtual',
        path: '/cartao-virtual',
        icon: FaRegAddressCard,
    },
    {
        title: 'Networking',
        path: '/networking',
        icon: BiNetworkChart,
        url: 'members',
        comunidade: true
    },
    {
        title: 'Área de cursos',
        path: '/cursos',
        icon: BsTrophy,
        url: 'courses',
        comunidade: true
    },
    {
        title: 'Agenda de eventos',
        path: '/agenda',
        icon: MdOutlineEvent,
        url: 'schedule',
        comunidade: true
    },
    {
        title: 'Notícias recentes',
        path: '/noticias',
        icon: FaRegNewspaper,
    },
    {
        title: 'Benefícios',
        path: '/beneficios',
        icon: RiListIndefinite,
    },
];