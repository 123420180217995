import noImage from "../../assets/img/noImage.png";
import Str from "../../helpers/Str";
import {RiTwitterXFill} from "react-icons/ri";
import {FaFacebook, FaInstagram, FaLinkedin} from "react-icons/fa";
import {MdOutlineWhatsapp} from "react-icons/md";
import QRCode from "react-qr-code";

interface IVirtualCardComponent {
    userData: any,
    on?: boolean
}

export default function VirtualCardComponent({userData, on}: IVirtualCardComponent) {
    const openInNewTab = (url: string|null) => {
        if (url){
            window.open(url, "_blank", "noreferrer");
        }
    };
    return (
        <div className={`flex flex-col w-full  ${on ? '' : 'justify-center'} items-center xs:mt-1`}>
            <div className={'w-[300px]  bg-[#42B14B] pt-5 pb-5 rounded-2xl'}>
                <div className={'flex justify-center'}>
                    <img className={'w-[110px] h-[110px] rounded-full'} src={userData.image_path ? userData.image_path : (userData.image ? userData.image : noImage)} alt={'Imagem usuario'}/>
                </div>
                <div className={'flex justify-center text-[20px] text-white mt-4'}>
                    {userData.nome ? userData.nome : userData.name }
                </div>
                <div className={'flex justify-center text-[15px] text-white mt-1'}>
                    {userData.email}
                </div>
                <div className={'flex justify-center text-[15px] text-white items-center mt-1'}>
                    {Str.addPhoneMask(userData.phone)}
                </div>
                <div className={'flex justify-center gap-5 mt-5'}>
                    <button onClick={() => openInNewTab('https://api.whatsapp.com/send?phone=' + userData.phone)}><MdOutlineWhatsapp size={20} color={"#FFF"}/></button>
                    <button onClick={() => openInNewTab(userData.twitterX)}><RiTwitterXFill size={20} color={"#FFF"}/></button>
                    <button onClick={() => openInNewTab(userData.facebook)}><FaFacebook size={20} color={"#FFF"}/></button>
                    <button onClick={() => openInNewTab(userData.instagram)}><FaInstagram size={20} color={"#FFF"}/></button>
                    <button onClick={() => openInNewTab(userData.linkedin)}><FaLinkedin size={20} color={"#FFF"}/></button>
                </div>

                <div className={'flex justify-center mt-5 '}>
                    <QRCode value={window.location.origin + '/cartao/' + (userData.nome ? userData.nome.replace(" ", '-').toLowerCase() : userData.name.replace(" ", '-').toLowerCase()) +'-'+ userData.id} size={167} />

                </div>
            </div>
        </div>
    )
}